var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-spin", { attrs: { spinning: _vm.skipLoading } }, [
    _c(
      "div",
      [
        _c(
          "div",
          {
            staticClass: "actions flex jc-end mb-4",
            staticStyle: { position: "relative" },
          },
          [
            _vm.viewType == "kanban"
              ? _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "260px", position: "relative" },
                        attrs: {
                          showSearch: "",
                          allowClear: "",
                          showArrow: "",
                          placeholder: "请选择完成状态",
                          mode: "multiple",
                          maxTagCount: 2,
                          "option-filter-prop": "label",
                        },
                        model: {
                          value: _vm.filterValue,
                          callback: function ($$v) {
                            _vm.filterValue = $$v
                          },
                          expression: "filterValue",
                        },
                      },
                      [
                        _c(
                          "a-select-option",
                          { attrs: { value: "FINISHED" } },
                          [_vm._v("已完成")]
                        ),
                        _c("a-select-option", { attrs: { value: "STARTED" } }, [
                          _vm._v("可开始"),
                        ]),
                        _c("a-select-option", { attrs: { value: "WAITING" } }, [
                          _vm._v("等待中"),
                        ]),
                        _c(
                          "a-select-option",
                          { attrs: { value: "REJECTING" } },
                          [_vm._v("驳回")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: { "margin-right": "15px", "line-height": "32px" },
              },
              [
                _c(
                  "a-tooltip",
                  { attrs: { placement: "bottom", transitionName: "" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "6px 12px",
                            "font-size": "12px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", height: "32px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    padding: "0 4px",
                                    background: "rgb(255 229 199)",
                                    color: "orange",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  任务名称\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    "margin-left": "5px",
                                  },
                                },
                                [_vm._v("驳回给他人的任务")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", height: "32px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    padding: "0 4px",
                                    background: "#fff9b7",
                                    color: "orange",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  任务名称\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    "margin-left": "5px",
                                  },
                                },
                                [_vm._v("拖稿的任务")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", height: "32px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    padding: "0 3px",
                                    "background-color": "rgb(255 225 225)",
                                    border: "1px solid #ffa39e",
                                    color: "orange",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  任务名称\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "5px",
                                    position: "relative",
                                    top: "-4px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  高优先级（需要在2小时内完成）"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "或截止日期在当日18点之前被驳回的任务\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", height: "32px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    padding: "0 4px",
                                    "background-color": "#f8f8f8",
                                    color: "orange",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  任务名称\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    "margin-left": "5px",
                                  },
                                },
                                [_vm._v("被驳回的任务")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", height: "32px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    padding: "0 4px",
                                    "background-color": "#fff9b7",
                                    color: "green",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  任务名称\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    "margin-left": "5px",
                                  },
                                },
                                [_vm._v("拖稿的已完成的任务")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", height: "32px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    padding: "0 4px",
                                    "background-color": "#f8f8f8",
                                    color: "green",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  任务名称\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    "margin-left": "5px",
                                  },
                                },
                                [_vm._v("常规已完成的任务")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", height: "32px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    padding: "0 4px",
                                    "background-color": "rgb(255 229 199)",
                                    color: "red",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  任务名称\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    "margin-left": "5px",
                                  },
                                },
                                [_vm._v("驳回过的可开始的任务")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", height: "32px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    padding: "0 4px",
                                    "background-color": "#f8f8f8",
                                    color: "red",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  任务名称\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    "margin-left": "5px",
                                  },
                                },
                                [_vm._v("常规可开始的任务")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", height: "32px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    padding: "0 3px",
                                    border: "1px solid orange",
                                  },
                                },
                                [_vm._v("任务名称")]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "28px",
                                    "margin-left": "5px",
                                  },
                                },
                                [_vm._v("被驳回过的任务")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "5px" } },
                                [
                                  _c("span", {
                                    staticClass: "circular",
                                    staticStyle: {
                                      background: "green",
                                      "margin-left": "3px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "3px" } },
                                    [_vm._v("已完成")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "5px" } },
                                [
                                  _c("span", {
                                    staticClass: "circular",
                                    staticStyle: {
                                      background: "red",
                                      "margin-left": "3px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "3px" } },
                                    [_vm._v("可开始")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "5px" } },
                                [
                                  _c("span", {
                                    staticClass: "circular",
                                    staticStyle: {
                                      background: "gray",
                                      "margin-left": "3px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "3px" } },
                                    [_vm._v("等待中")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "5px" } },
                                [
                                  _c("span", {
                                    staticClass: "circular",
                                    staticStyle: {
                                      background: "orange",
                                      "margin-left": "3px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "3px" } },
                                    [_vm._v("驳回")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("a-icon", {
                      staticStyle: { "font-size": "16px" },
                      attrs: { type: "question-circle" },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm.viewType != "calendar" && _vm.parent.isTongChou
              ? _c(
                  "a-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.viewType != "calendar",
                        expression: "viewType != 'calendar'",
                      },
                    ],
                    staticClass: "mr-3",
                    attrs: { type: "primary", icon: "hdd" },
                    on: {
                      click: function ($event) {
                        _vm.recycleBinVisible = true
                      },
                    },
                  },
                  [_vm._v("回收站")]
                )
              : _vm._e(),
            _vm.viewType != "calendar" && _vm.parent.isTongChou
              ? _c("a-button", {
                  staticStyle: {
                    width: "45px",
                    "border-right": "none",
                    "border-top-right-radius": "unset",
                    "border-bottom-right-radius": "unset",
                  },
                  attrs: { icon: "delete" },
                  on: { click: _vm.batchDel },
                })
              : _vm._e(),
            _vm.viewType == "kanban"
              ? _c(
                  "a-button",
                  {
                    staticStyle: {
                      width: "45px",
                      "border-right": "none",
                      "border-radius": "unset",
                    },
                    on: {
                      click: _vm.sortKanban,
                      mouseover: function ($event) {
                        _vm.color1 = _vm.color
                      },
                      mouseout: function ($event) {
                        _vm.color1 = "#4a4a4a"
                      },
                    },
                  },
                  [
                    _c("sort-two", {
                      staticStyle: {
                        position: "relative",
                        left: "-2px",
                        top: "2px",
                      },
                      attrs: {
                        theme: "outline",
                        size: "18",
                        fill: _vm.color1,
                        strokeWidth: 3,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.viewType == "calendar"
              ? _c(
                  "a-dropdown",
                  { attrs: { placement: "bottomCenter" } },
                  [
                    _c("a-button", {
                      staticStyle: {
                        width: "45px",
                        "border-right": "none",
                        "border-top-right-radius": "unset",
                        "border-bottom-right-radius": "unset",
                      },
                      attrs: { icon: "check-square" },
                    }),
                    _c(
                      "a-menu",
                      { attrs: { slot: "overlay" }, slot: "overlay" },
                      [
                        _vm.parent.isTongChou && _vm.viewType == "calendar"
                          ? _c(
                              "a-menu-item",
                              {
                                attrs: { disabled: _vm.isCalendarCancel },
                                on: { click: _vm.changeDropSelect },
                              },
                              [_vm._v("\n            批量拖拽\n          ")]
                            )
                          : _vm._e(),
                        _c(
                          "a-menu-item",
                          {
                            attrs: { disabled: _vm.isCalendarDropCancel },
                            on: { click: _vm.changeSelect },
                          },
                          [_vm._v("\n            批量完成/下载\n          ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "a-dropdown",
              {
                attrs: {
                  disabled:
                    !_vm.selectionRows.length ||
                    _vm.selectionRows.some(
                      (item) => item.taskName != _vm.selectionRows[0].taskName
                    ) ||
                    !(
                      !_vm.selectionRows.some(
                        (item) => item.taskName != "给稿"
                      ) ||
                      _vm.selectionRows.every(
                        (item) =>
                          item.taskName == "制作" &&
                          item.systemTypes &&
                          item.systemTypes.some(
                            (item) => item == "IMAGE_TRANSLATION_SYSTEM"
                          )
                      ) ||
                      _vm.selectionRows.every(
                        (item) =>
                          (item.taskName == "监制确认" ||
                            item.taskName == "监制" ||
                            item.taskName == "交稿") &&
                          item.applyReview
                      ) ||
                      _vm.selectionRows.every(
                        (item) =>
                          (item.taskName == "制作" ||
                            item.taskName == "制作修改" ||
                            item.stageName == "监制" ||
                            item.stageName == "监制确认" ||
                            item.stageName.indexOf("建模") > -1 ||
                            item.stageName.indexOf("精草") > -1 ||
                            item.stageName.indexOf("勾线") > -1 ||
                            item.stageName.indexOf("上色") > -1 ||
                            item.stageName.indexOf("后期") > -1) &&
                          item.applyReview
                      )
                    ),
                  placement: "bottomCenter",
                },
              },
              [
                _c("a-button", {
                  staticStyle: {
                    width: "45px",
                    "border-right": "none",
                    "border-radius": "unset",
                  },
                  attrs: { icon: "download" },
                }),
                _c(
                  "a-menu",
                  { attrs: { slot: "overlay" }, slot: "overlay" },
                  [
                    !_vm.selectionRows.some((item) => item.taskName != "给稿")
                      ? _c(
                          "a-menu-item",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "myTasks::button:admin",
                                expression: "'myTasks::button:admin'",
                              },
                            ],
                            on: { click: _vm.openManuscriptList },
                          },
                          [_vm._v("上传原稿")]
                        )
                      : _vm._e(),
                    _vm.selectionRows.every(
                      (item) =>
                        (item.taskName == "制作" ||
                          item.taskName == "制作修改" ||
                          item.stageName == "监制" ||
                          item.stageName == "监制确认" ||
                          item.stageName.indexOf("建模") > -1 ||
                          item.stageName.indexOf("精草") > -1 ||
                          item.stageName.indexOf("勾线") > -1 ||
                          item.stageName.indexOf("上色") > -1 ||
                          item.stageName.indexOf("后期") > -1) &&
                        item.applyReview &&
                        ((item.taskStatus != "等待" &&
                          item.taskStatus != "驳回" &&
                          item.taskStatus != "已完成") ||
                          (item.taskStatus == "驳回" && item.rejectTaskId))
                    )
                      ? _c(
                          "a-menu-item",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "myTasks::button:admin",
                                expression: "'myTasks::button:admin'",
                              },
                            ],
                            on: { click: _vm.openSumbmitList },
                          },
                          [_vm._v("上传完成稿")]
                        )
                      : _vm._e(),
                    _vm.selectionRows.every(
                      (item) =>
                        item.taskName == "制作" &&
                        item.systemTypes &&
                        item.systemTypes.some(
                          (item) => item == "IMAGE_TRANSLATION_SYSTEM"
                        )
                    )
                      ? _c(
                          "a-menu-item",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "myTasks::button:admin",
                                expression: "'myTasks::button:admin'",
                              },
                            ],
                            on: { click: _vm.downloadMake },
                          },
                          [_vm._v("下载制作稿")]
                        )
                      : _vm._e(),
                    _vm.selectionRows.every(
                      (item) =>
                        (item.taskName == "监制确认" ||
                          item.stageName == "勾线终审" ||
                          item.stageName == "上色终审" ||
                          item.taskName == "监制" ||
                          item.taskName == "交稿") &&
                        item.applyReview
                    )
                      ? _c(
                          "a-menu-item",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "myTasks::button:admin",
                                expression: "'myTasks::button:admin'",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.downloadOver(false)
                              },
                            },
                          },
                          [_vm._v("下载终稿")]
                        )
                      : _vm._e(),
                    _vm.selectionRows.every(
                      (item) =>
                        (item.taskName == "监制确认" ||
                          item.taskName == "监制") &&
                        item.applyReview &&
                        item.departName.indexOf("日语部") > -1
                    )
                      ? _c(
                          "a-menu-item",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "myTasks::button:admin",
                                expression: "'myTasks::button:admin'",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.downloadOver(true)
                              },
                            },
                          },
                          [_vm._v("下载切片")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.parent.isTongChou && _vm.viewType != "calendar"
              ? _c("a-button", {
                  staticStyle: {
                    width: "45px",
                    "border-right": "none",
                    "border-radius": "unset",
                  },
                  attrs: {
                    icon: "edit",
                    disabled:
                      _vm.isCalendarDropCancel || !this.selectionRows.length,
                  },
                  on: { click: _vm.openMonthList },
                })
              : _vm._e(),
            _c(
              "a-dropdown",
              {
                attrs: {
                  visible: _vm.visible,
                  disabled:
                    (_vm.selectedRowKeys.length == 0 &&
                      _vm.selectKeys.length == 0) ||
                    (_vm.selectKeys.length &&
                      _vm.selectKeys.some(
                        (item) =>
                          item.taskStatus != _vm.selectKeys[0].taskStatus
                      )) ||
                    (_vm.selectKeys.length &&
                      _vm.selectKeys.some(
                        (item) => item.stageName != _vm.selectKeys[0].stageName
                      )) ||
                    _vm.selectKeys.some((item) => {
                      return !item.rejectTaskId && item.taskStatus === "驳回"
                    }) ||
                    _vm.selectKeys.some((item) => {
                      return item.taskStatus === "等待"
                    }) ||
                    (!_vm.checkPermission() &&
                      _vm.selectKeys.some(
                        (item) => item.ownerId != _vm.userInfo.id
                      )),
                  trigger: ["click"],
                },
                on: { visibleChange: _vm.visibleChange },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: {
                      "border-top-left-radius": "unset",
                      "border-bottom-left-radius": "unset",
                      "margin-right": "12px",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.getRejectStages.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("完成状态")]
                ),
                _vm.selectedRowKeys.length || _vm.selectKeys.length
                  ? _c(
                      "a-menu",
                      { attrs: { slot: "overlay" }, slot: "overlay" },
                      [
                        _vm.selectKeys.every(
                          (item) => item.taskStatus !== "已完成"
                        ) &&
                        _vm.selectKeys.every(
                          (item) => item.stageName != "甲方反馈"
                        )
                          ? _c(
                              "a-menu-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTaskFine(false)
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _c("span", {
                                    staticClass: "circular",
                                    staticStyle: {
                                      background: "green",
                                      "margin-left": "3px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "5px" } },
                                    [_vm._v("已完成")]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectKeys.every(
                          (item) => item.taskStatus == "已完成"
                        ) && _vm.parent.isTongChou
                          ? _c(
                              "a-menu-item",
                              { on: { click: _vm.handleTaskStart } },
                              [
                                _c("div", [
                                  _c("span", {
                                    staticClass: "circular",
                                    staticStyle: {
                                      background: "red",
                                      "margin-left": "3px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "5px" } },
                                    [_vm._v("可开始")]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectKeys.every(
                          (item) => item.taskStatus !== "已完成"
                        ) &&
                        _vm.selectKeys.every(
                          (item) => item.stageName == "甲方反馈"
                        )
                          ? _c(
                              "a-menu-item",
                              {
                                staticClass: "status-item",
                                attrs: {
                                  disabled: _vm.selectKeys.some(
                                    (item) => item.hasFeedback
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTaskFine(false)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "texe-align": "center",
                                      "padding-left": "10px",
                                    },
                                  },
                                  [_vm._v("无反馈")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectKeys.every(
                          (item) => item.taskStatus !== "已完成"
                        ) &&
                        _vm.selectKeys.every(
                          (item) => item.stageName == "甲方反馈"
                        ) &&
                        !_vm.rejectShow
                          ? _c(
                              "a-menu-item",
                              {
                                staticClass: "status-item",
                                on: { click: () => (this.rejectShow = true) },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "texe-align": "center",
                                      "padding-left": "10px",
                                    },
                                  },
                                  [_vm._v("有反馈")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectKeys.every(
                          (item) => item.taskStatus !== "已完成"
                        ) &&
                        _vm.selectKeys.every(
                          (item) => item.stageName == "甲方反馈"
                        ) &&
                        _vm.rejectShow
                          ? _c(
                              "a-menu-item",
                              {
                                staticClass: "status-item",
                                on: { click: () => _vm.handleTaskFine(true) },
                              },
                              [
                                _c("div", {
                                  staticClass: "circular",
                                  staticStyle: {
                                    background: "green",
                                    "margin-left": "3px",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [_vm._v("已完成")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectKeys.every(
                          (item) => item.taskStatus !== "已完成"
                        ) &&
                        _vm.selectKeys.every(
                          (item) => item.taskStatus !== "驳回"
                        ) &&
                        _vm.selectKeys.every(
                          (item) => item.stageName !== "给稿"
                        ) &&
                        _vm.rejectStages.length > 0 &&
                        _vm.rejectShow
                          ? _vm._l(_vm.rejectStages, function (item, idx) {
                              return _vm.rejectStages.length > 1
                                ? _c(
                                    "a-menu-item",
                                    { key: idx },
                                    [
                                      _c(
                                        "a-checkbox",
                                        {
                                          on: {
                                            change: (e) => {
                                              _vm.onChange(e, item)
                                            },
                                          },
                                          model: {
                                            value: item.checked,
                                            callback: function ($$v) {
                                              _vm.$set(item, "checked", $$v)
                                            },
                                            expression: "item.checked",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-dropdown",
                                            { attrs: { trigger: ["hover"] } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "line-height": "30px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "驳回-" +
                                                      _vm._s(item.stageName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "absolute",
                                                    top: "-4vh",
                                                    left: "4.5vw",
                                                    "box-shadow":
                                                      "0 2px 8px rgb(0 0 0 / 15%)",
                                                  },
                                                  attrs: { slot: "overlay" },
                                                  slot: "overlay",
                                                },
                                                [
                                                  _c(
                                                    "a-menu",
                                                    [
                                                      _c(
                                                        "a-menu-item",
                                                        {
                                                          key: "HIGH",
                                                          staticStyle: {
                                                            height: "30px",
                                                            "line-height":
                                                              "30px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.priorityChange(
                                                                item,
                                                                "高"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "高\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-menu-item",
                                                        {
                                                          key: "MIDDLE",
                                                          staticStyle: {
                                                            height: "30px",
                                                            "line-height":
                                                              "30px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.priorityChange(
                                                                item,
                                                                "普通"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "普通\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-menu-item",
                                                        {
                                                          key: "LOW",
                                                          staticStyle: {
                                                            height: "30px",
                                                            "line-height":
                                                              "30px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.priorityChange(
                                                                item,
                                                                "低"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "低\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._l(
                                    _vm.rejectStages,
                                    function (item, idx) {
                                      return _c(
                                        "a-sub-menu",
                                        { key: "submenu" + idx },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                                "line-height": "30px",
                                                "min-width": "100%",
                                              },
                                              attrs: { slot: "title" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.priorityChange(
                                                    item,
                                                    "普通"
                                                  )
                                                },
                                              },
                                              slot: "title",
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "statusPointer",
                                                style: { background: "orange" },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "2px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " 驳回-" +
                                                      _vm._s(item.stageName)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-menu-item",
                                            {
                                              key: "HIGH",
                                              staticStyle: {
                                                "line-height": "30px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.priorityChange(
                                                    item,
                                                    "高"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("高 ")]
                                          ),
                                          _c(
                                            "a-menu-item",
                                            {
                                              key: "MIDDLE",
                                              staticStyle: {
                                                "line-height": "30px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.priorityChange(
                                                    item,
                                                    "普通"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("普通\n              ")]
                                          ),
                                          _c(
                                            "a-menu-item",
                                            {
                                              key: "LOW",
                                              staticStyle: {
                                                "line-height": "30px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.priorityChange(
                                                    item,
                                                    "低"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("低 ")]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                            })
                          : _vm._e(),
                        _vm.rejectStages.length > 1 &&
                        _vm.selectKeys.every(
                          (item) => item.taskStatus !== "已完成"
                        ) &&
                        _vm.selectKeys.every(
                          (item) => item.taskStatus !== "驳回"
                        ) &&
                        _vm.rejectShow
                          ? _c(
                              "a-menu-item",
                              {
                                staticStyle: {
                                  "background-color": "#fff !important",
                                },
                              },
                              [
                                _c("div", { staticClass: "task-btn" }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { width: "50%" },
                                      on: {
                                        click: () => {
                                          this.visible = !this.visible
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                取消\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      style: `color:${_vm.color};width:50%`,
                                      on: { click: _vm.submitTask },
                                    },
                                    [_vm._v("确定")]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "a-button-group",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n            看板\n          "),
                    ]),
                    _c("a-button", {
                      style:
                        _vm.viewType == "kanban"
                          ? "width:45px;z-index:9"
                          : "width:45px",
                      attrs: {
                        icon: "appstore",
                        type: _vm.viewType == "kanban" ? "primary" : "",
                        ghost: _vm.viewType == "kanban" ? true : false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeViewType("kanban")
                        },
                      },
                    }),
                  ],
                  2
                ),
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n            列表\n          "),
                    ]),
                    _c("a-button", {
                      style:
                        _vm.viewType == "list"
                          ? "width:45px;z-index:9"
                          : "width:45px",
                      attrs: {
                        icon: "unordered-list",
                        type: _vm.viewType == "list" ? "primary" : "",
                        ghost: _vm.viewType == "list" ? true : false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeViewType("list")
                        },
                      },
                    }),
                  ],
                  2
                ),
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("\n            日历\n          "),
                    ]),
                    _c("a-button", {
                      style:
                        _vm.viewType == "calendar"
                          ? "width:45px;z-index:9"
                          : "width:45px",
                      attrs: {
                        icon: "table",
                        type: _vm.viewType == "calendar" ? "primary" : "",
                        ghost: _vm.viewType == "calendar" ? true : false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeViewType("calendar")
                        },
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.viewType == "list"
          ? _c("tab-tasks-table", {
              ref: "table",
              attrs: { parent: _vm.parent, allUsers: _vm.allUsers },
              on: { setRowkeys: _vm.setRowkeys },
            })
          : _vm._e(),
        _vm.viewType == "calendar"
          ? _c("panel-calendar", {
              ref: "calendarPanel",
              attrs: {
                isWeek: _vm.isWeek,
                calendarType: _vm.calendarType,
                parent: _vm.parent,
                userInfo: _vm.userInfo,
                selectKeys: _vm.selectKeys,
              },
              on: {
                changeFinishDis: _vm.changeFinishDis,
                changeViewTypeToWeek: _vm.changeViewTypeToWeek,
                getKey: _vm.getKey,
                setSkipLoading: _vm.setSkipLoading,
                setSelectKeys: _vm.setSelectKeys,
              },
            })
          : _vm._e(),
        _vm.viewType == "kanban"
          ? _c("task-board", {
              ref: "kanBan",
              attrs: {
                selectKeys: _vm.selectKeys,
                filterValue: _vm.filterValue,
                parent: _vm.parent,
              },
              on: {
                getKey: _vm.getKey,
                setSelectKeys: _vm.setSelectKeys,
                setBatchDis: _vm.setBatchDis,
              },
            })
          : _vm._e(),
        _c("month-list", {
          ref: "monthList",
          attrs: { allUsers: _vm.allUsers, viewType: _vm.viewType },
          on: {
            changeTaskList: _vm.changeTaskList,
            getTaskTableData: _vm.getTaskTableData,
          },
        }),
        _c("tasks-model", {
          ref: "tasksModel",
          on: { closeTasksModel: _vm.closeTasksModel },
        }),
        _c("tasks-recycle-bin-modal", {
          attrs: {
            productionId: _vm.parent.id,
            visible: _vm.recycleBinVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.recycleBinVisible = $event
            },
            ok: _vm.modalFormOk,
          },
        }),
        _c("manuscript-list", {
          ref: "manuscript",
          attrs: { title: "上传原稿", selectionRows: _vm.selectionRows },
        }),
        _c("sumbmit-modal", {
          ref: "sumbmitModal",
          attrs: { title: "上传完成稿", selectionRows: _vm.selectionRows },
          on: { openTaskModel: _vm.openTaskModel },
        }),
        _c("term-list-modal", { ref: "termListModal" }),
        _c("font-modal", { ref: "fontModal", on: { saveFont: _vm.saveFont } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }