<template>
  <a-spin :spinning="skipLoading">
    <div>
      <div class="actions flex jc-end mb-4" style="position:relative">
        <div style="flex:1" v-if="viewType == 'kanban'">
          <a-select
            showSearch
            allowClear
            showArrow
            placeholder="请选择完成状态"
            mode="multiple"
            v-model="filterValue"
            :maxTagCount="2"
            option-filter-prop="label"
            style="width: 260px;position: relative;"
          >
            <a-select-option value="FINISHED">已完成</a-select-option>
            <a-select-option value="STARTED">可开始</a-select-option>
            <a-select-option value="WAITING">等待中</a-select-option>
            <a-select-option value="REJECTING">驳回</a-select-option>
          </a-select>
        </div>
        <div style="margin-right:15px;line-height:32px;">
          <a-tooltip placement="bottom" transitionName="">
            <template slot="title">
              <div style="padding:6px 12px;font-size:12px">
                <!-- 最终显示效果与文案不匹配，以显示效果为准 -->
                <div style="display:flex;height:32px;">
                  <div style="height:28px;line-height:28px;padding:0 4px;background:rgb(255 229 199);color:orange;">
                    任务名称
                  </div>
                  <div style="height:28px;line-height:28px;margin-left:5px;">驳回给他人的任务</div>
                </div>
                <div style="display:flex;height:32px;">
                  <div style="height:28px;line-height:28px;padding:0 4px;background:#fff9b7;color:orange;">
                    任务名称
                  </div>
                  <div style="height:28px;line-height:28px;margin-left:5px;">拖稿的任务</div>
                </div>
                <div style="display:flex;height:32px;">
                  <div
                    style="height:28px;line-height:28px;padding:0 3px;background-color:rgb(255 225 225);border: 1px solid #ffa39e;color:orange;"
                  >
                    任务名称
                  </div>
                  <div style="margin-left:5px;position: relative;top: -4px;">
                    高优先级（需要在2小时内完成）<br />或截止日期在当日18点之前被驳回的任务
                  </div>
                </div>
                <div style="display:flex;height:32px;">
                  <div style="height:28px;line-height:28px;padding:0 4px;background-color:#f8f8f8;color:orange;">
                    任务名称
                  </div>
                  <div style="height:28px;line-height:28px;margin-left:5px;">被驳回的任务</div>
                </div>
                <div style="display:flex;height:32px;">
                  <div style="height:28px;line-height:28px;padding:0 4px;background-color:#fff9b7;color:green;">
                    任务名称
                  </div>
                  <div style="height:28px;line-height:28px;margin-left:5px;">拖稿的已完成的任务</div>
                </div>
                <div style="display:flex;height:32px;">
                  <div style="height:28px;line-height:28px;padding:0 4px;background-color:#f8f8f8;color:green;">
                    任务名称
                  </div>
                  <div style="height:28px;line-height:28px;margin-left:5px;">常规已完成的任务</div>
                </div>
                <div style="display:flex;height:32px;">
                  <div style="height:28px;line-height:28px;padding:0 4px;background-color:rgb(255 229 199);color:red;">
                    任务名称
                  </div>
                  <div style="height:28px;line-height:28px;margin-left:5px;">驳回过的可开始的任务</div>
                </div>
                <div style="display:flex;height:32px;">
                  <div style="height:28px;line-height:28px;padding:0 4px;background-color:#f8f8f8;color:red;">
                    任务名称
                  </div>
                  <div style="height:28px;line-height:28px;margin-left:5px;">常规可开始的任务</div>
                </div>
                <div style="display:flex;height:32px;">
                  <div style="height:28px;line-height:28px;padding:0 3px;border: 1px solid orange;">任务名称</div>
                  <div style="height:28px;line-height:28px;margin-left:5px;">被驳回过的任务</div>
                </div>
                <div style="display:flex;margin-top:10px">
                  <div style="margin-left: 5px;">
                    <span class="circular" style="background: green;margin-left:3px"></span>
                    <span style="margin-left: 3px;">已完成</span>
                  </div>
                  <div style="margin-left: 5px;">
                    <span class="circular" style="background: red;margin-left:3px"></span>
                    <span style="margin-left: 3px;">可开始</span>
                  </div>
                  <div style="margin-left: 5px;">
                    <span class="circular" style="background: gray;margin-left:3px"></span>
                    <span style="margin-left: 3px;">等待中</span>
                  </div>
                  <div style="margin-left: 5px;">
                    <span class="circular" style="background: orange;margin-left:3px"></span>
                    <span style="margin-left: 3px;">驳回</span>
                  </div>
                </div>
              </div>
            </template>
            <a-icon type="question-circle" style="font-size:16px;" />
          </a-tooltip>
        </div>
        <a-button
          type="primary"
          class="mr-3"
          v-show="viewType != 'calendar'"
          @click="recycleBinVisible = true"
          icon="hdd"
          v-if="viewType != 'calendar' && parent.isTongChou"
          >回收站</a-button
        >
        <a-button
          @click="batchDel"
          icon="delete"
          style="width:45px;border-right:none;border-top-right-radius: unset;border-bottom-right-radius: unset;"
          v-if="viewType != 'calendar' && parent.isTongChou"
        ></a-button>
        <a-button
          @click="sortKanban"
          style="width:45px;border-right:none;border-radius: unset;"
          v-if="viewType == 'kanban'"
          @mouseover="color1 = color"
          @mouseout="color1 = '#4a4a4a'"
          ><sort-two
            style="position: relative;left: -2px;top: 2px;"
            theme="outline"
            size="18"
            :fill="color1"
            :strokeWidth="3"
        /></a-button>
        <a-dropdown :placement="'bottomCenter'" v-if="viewType == 'calendar'">
          <a-button
            icon="check-square"
            style="width:45px;border-right:none;border-top-right-radius: unset;border-bottom-right-radius: unset;"
          ></a-button>
          <a-menu slot="overlay">
            <!--             <a-menu-item
              v-if="parent.isTongChou && viewType != 'calendar'"
              :disabled="isCalendarDropCancel"
              @click="changeEditSelect"
              >{{ isEditSelect ? '退出编辑' : '批量编辑' }}</a-menu-item
            > -->
            <a-menu-item
              v-if="parent.isTongChou && viewType == 'calendar'"
              :disabled="isCalendarCancel"
              @click="changeDropSelect"
            >
              批量拖拽
            </a-menu-item>
            <a-menu-item :disabled="isCalendarDropCancel" @click="changeSelect">
              批量完成/下载
            </a-menu-item>
            <!--             <a-menu-item
              v-if="parent.isTongChou && viewType != 'calendar'"
              :disabled="
                selectionRows.length
                  ? selectionRows
                      .filter(item => {
                        if (viewType == 'list') {
                          return item.chapterSortAndStageName
                        } else if (viewType == 'kanban') {
                          return item
                        }
                      })
                      .some(item => {
                        return (
                          (!selectionRows[selectionRows.length - 1].stageList &&
                            selectionRows[selectionRows.length - 1].stageName != selectionRows[0].stageName) ||
                          (selectionRows[selectionRows.length - 1].stageList &&
                            selectionRows[selectionRows.length - 1].stageList.find(
                              i => i.stageName == selectionRows[selectionRows.length - 1].stageName
                            ).positionId != item.stageList.find(i => i.stageName == item.stageName).positionId) ||
                          selectionRows[selectionRows.length - 1].productName != item.productName
                        )
                      })
                  : false
              "
              @click="changeUserSelect"
              >批量换人</a-menu-item
            > -->
          </a-menu>
        </a-dropdown>
        <!-- v-if="
            !selectionRows.some(item => item.taskName != '给稿') ||
              selectionRows.every(item => item.taskName == '制作' && item.applyTranslation) ||
              selectionRows.every(
                item => (item.taskName == '监制确认' || item.taskName == '监制') && item.applyReview
              ) ||
              selectionRows.every(
                item =>
                  (item.taskName == '制作' ||
                    item.taskName == '制作修改' ||
                    item.stageName == '监制' ||
                    item.stageName == '监制确认') &&
                  item.applyReview &&
                  ((item.taskStatus != '等待' && item.taskStatus != '驳回' && item.taskStatus != '已完成') ||
                    (item.taskStatus == '驳回' && item.rejectTaskId))
              )
          " -->
        <a-dropdown
          :disabled="
            !selectionRows.length ||
              selectionRows.some(item => item.taskName != selectionRows[0].taskName) ||
              !(
                !selectionRows.some(item => item.taskName != '给稿') ||
                selectionRows.every(
                  item =>
                    item.taskName == '制作' &&
                    item.systemTypes &&
                    item.systemTypes.some(item => item == 'IMAGE_TRANSLATION_SYSTEM')
                ) ||
                selectionRows.every(
                  item =>
                    (item.taskName == '监制确认' || item.taskName == '监制' || item.taskName == '交稿') &&
                    item.applyReview
                ) ||
                selectionRows.every(
                  item =>
                    (item.taskName == '制作' ||
                      item.taskName == '制作修改' ||
                      item.stageName == '监制' ||
                      item.stageName == '监制确认' ||
                      item.stageName.indexOf('建模') > -1 ||
                      item.stageName.indexOf('精草') > -1 ||
                      item.stageName.indexOf('勾线') > -1 ||
                      item.stageName.indexOf('上色') > -1 ||
                      item.stageName.indexOf('后期') > -1) &&
                    item.applyReview
                )
              )
          "
          :placement="'bottomCenter'"
        >
          <a-button icon="download" style="width:45px;border-right:none;border-radius: unset;"></a-button>
          <a-menu slot="overlay">
            <a-menu-item
              v-has="'myTasks::button:admin'"
              @click="openManuscriptList"
              v-if="!selectionRows.some(item => item.taskName != '给稿')"
              >上传原稿</a-menu-item
            >
            <a-menu-item
              v-has="'myTasks::button:admin'"
              @click="openSumbmitList"
              v-if="
                selectionRows.every(
                  item =>
                    (item.taskName == '制作' ||
                      item.taskName == '制作修改' ||
                      item.stageName == '监制' ||
                      item.stageName == '监制确认' ||
                      item.stageName.indexOf('建模') > -1 ||
                      item.stageName.indexOf('精草') > -1 ||
                      item.stageName.indexOf('勾线') > -1 ||
                      item.stageName.indexOf('上色') > -1 ||
                      item.stageName.indexOf('后期') > -1) &&
                    item.applyReview &&
                    ((item.taskStatus != '等待' && item.taskStatus != '驳回' && item.taskStatus != '已完成') ||
                      (item.taskStatus == '驳回' && item.rejectTaskId))
                )
              "
              >上传完成稿</a-menu-item
            >
            <a-menu-item
              v-has="'myTasks::button:admin'"
              @click="downloadMake"
              v-if="
                selectionRows.every(
                  item =>
                    item.taskName == '制作' &&
                    item.systemTypes &&
                    item.systemTypes.some(item => item == 'IMAGE_TRANSLATION_SYSTEM')
                )
              "
              >下载制作稿</a-menu-item
            >
            <a-menu-item
              v-has="'myTasks::button:admin'"
              @click="downloadOver(false)"
              v-if="
                selectionRows.every(
                  item =>
                    (item.taskName == '监制确认' ||
                      item.stageName == '勾线终审' ||
                      item.stageName == '上色终审' ||
                      item.taskName == '监制' ||
                      item.taskName == '交稿') &&
                    item.applyReview
                )
              "
              >下载终稿</a-menu-item
            >
            <a-menu-item
              v-has="'myTasks::button:admin'"
              @click="downloadOver(true)"
              v-if="
                selectionRows.every(
                  item =>
                    (item.taskName == '监制确认' || item.taskName == '监制') &&
                    item.applyReview &&
                    item.departName.indexOf('日语部') > -1
                )
              "
              >下载切片</a-menu-item
            >
          </a-menu>
        </a-dropdown>
        <a-button
          icon="edit"
          :disabled="isCalendarDropCancel || !this.selectionRows.length"
          @click="openMonthList"
          v-if="parent.isTongChou && viewType != 'calendar'"
          style="width:45px;border-right:none;border-radius: unset;"
        ></a-button>
        <a-dropdown
          :visible="visible"
          :disabled="
            (selectedRowKeys.length == 0 && selectKeys.length == 0) ||
              (selectKeys.length && selectKeys.some(item => item.taskStatus != selectKeys[0].taskStatus)) ||
              (selectKeys.length && selectKeys.some(item => item.stageName != selectKeys[0].stageName)) ||
              selectKeys.some(item => {
                return !item.rejectTaskId && item.taskStatus === '驳回'
              }) ||
              selectKeys.some(item => {
                return item.taskStatus === '等待'
              }) ||
              (!checkPermission() && selectKeys.some(item => item.ownerId != userInfo.id))
          "
          @visibleChange="visibleChange"
          :trigger="['click']"
        >
          <a-button
            style="border-top-left-radius: unset;border-bottom-left-radius: unset;margin-right:12px"
            @click.stop="getRejectStages"
            >完成状态</a-button
          >
          <a-menu slot="overlay" v-if="selectedRowKeys.length || selectKeys.length">
            <a-menu-item
              @click="handleTaskFine(false)"
              v-if="
                selectKeys.every(item => item.taskStatus !== '已完成') &&
                  selectKeys.every(item => item.stageName != '甲方反馈')
              "
            >
              <div>
                <span class="circular" style="background: green;margin-left:3px"></span>
                <span style="margin-left: 5px;">已完成</span>
              </div>
            </a-menu-item>
            <a-menu-item
              @click="handleTaskStart"
              v-if="selectKeys.every(item => item.taskStatus == '已完成') && parent.isTongChou"
            >
              <div>
                <span class="circular" style="background: red;margin-left:3px"></span>
                <span style="margin-left: 5px;">可开始</span>
              </div>
            </a-menu-item>
            <a-menu-item
              @click="handleTaskFine(false)"
              class="status-item"
              :disabled="selectKeys.some(item => item.hasFeedback)"
              v-if="
                selectKeys.every(item => item.taskStatus !== '已完成') &&
                  selectKeys.every(item => item.stageName == '甲方反馈')
              "
            >
              <div style="texe-align:center;padding-left:10px">无反馈</div>
            </a-menu-item>
            <a-menu-item
              @click="() => (this.rejectShow = true)"
              class="status-item"
              v-if="
                selectKeys.every(item => item.taskStatus !== '已完成') &&
                  selectKeys.every(item => item.stageName == '甲方反馈') &&
                  !rejectShow
              "
            >
              <div style="texe-align:center;padding-left:10px">有反馈</div>
            </a-menu-item>
            <a-menu-item
              @click="() => handleTaskFine(true)"
              class="status-item"
              v-if="
                selectKeys.every(item => item.taskStatus !== '已完成') &&
                  selectKeys.every(item => item.stageName == '甲方反馈') &&
                  rejectShow
              "
            >
              <div class="circular" style="background: green;margin-left:3px"></div>
              <span style="margin-left:5px">已完成</span>
            </a-menu-item>
            <!--             <a-menu-item
              v-if="
                selectionRows.some(item => getTaskStatus(item.taskStatus) !== '驳回') &&
                  selectionRows.some(item => item.stageName !== '给稿') &&
                  rejectStages.length > 0
              "
            >
              <a-dropdown :trigger="['click']">
                <div>
                  <span class="circular" style="background: orange"></span>
                  <span>驳回</span>
                </div>
                <div
                  slot="overlay"
                  style=" position: absolute; top: -3.6vh; left: 4.2vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                >
                  <a-menu v-for="item in rejectStages" :key="item.stageId">
                    <a-menu-item style="height:30px;" @click="handleTasks(item.stageId, 'MIDDLE')">
                      <a-dropdown>
                        <div style="line-height:30px;">{{ item.stageName }}</div>
                        <div
                          slot="overlay"
                          style=" position: absolute; top: -4vh; left: 2.6vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                        >
                          <a-menu>
                            <a-menu-item
                              style="height:30px;line-height:30px;"
                              @click="handleTasks(item.stageId, 'HIGH')"
                              >高</a-menu-item
                            >
                            <a-menu-item
                              style="height:30px;line-height:30px;"
                              @click="handleTasks(item.stageId, 'MIDDLE')"
                              >普通</a-menu-item
                            >
                            <a-menu-item style="height:30px;line-height:30px;" @click="handleTasks(item.stageId, 'LOW')"
                              >低</a-menu-item
                            >
                          </a-menu>
                        </div>
                      </a-dropdown>
                    </a-menu-item>
                  </a-menu>
                </div>
              </a-dropdown>
            </a-menu-item> -->
            <template
              v-if="
                selectKeys.every(item => item.taskStatus !== '已完成') &&
                  selectKeys.every(item => item.taskStatus !== '驳回') &&
                  selectKeys.every(item => item.stageName !== '给稿') &&
                  rejectStages.length > 0 &&
                  rejectShow
              "
            >
              <a-menu-item v-if="rejectStages.length > 1" v-for="(item, idx) in rejectStages" :key="idx">
                <a-checkbox
                  v-model="item.checked"
                  @change="
                    e => {
                      onChange(e, item)
                    }
                  "
                >
                  <a-dropdown :trigger="['hover']">
                    <div style="line-height:30px;">驳回-{{ item.stageName }}</div>
                    <div
                      slot="overlay"
                      style=" position: absolute; top: -4vh; left: 4.5vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                    >
                      <a-menu>
                        <a-menu-item
                          style="height:30px;line-height:30px;"
                          @click="priorityChange(item, '高')"
                          key="HIGH"
                          >高
                        </a-menu-item>
                        <a-menu-item
                          style="height:30px;line-height:30px;"
                          key="MIDDLE"
                          @click="priorityChange(item, '普通')"
                          >普通
                        </a-menu-item>
                        <a-menu-item style="height:30px;line-height:30px;" @click="priorityChange(item, '低')" key="LOW"
                          >低
                        </a-menu-item>
                      </a-menu>
                    </div>
                  </a-dropdown>
                </a-checkbox>
              </a-menu-item>

              <a-sub-menu v-else v-for="(item, idx) in rejectStages" :key="'submenu' + idx">
                <div
                  slot="title"
                  style="display:flex;align-items:center;line-height:30px;min-width: 100%;"
                  @click="priorityChange(item, '普通')"
                >
                  <div class="statusPointer" :style="{ background: 'orange' }"></div>
                  <div style="margin-left: 2px;">&nbsp;驳回-{{ item.stageName }}</div>
                </div>
                <!-- <div slot="overlay"
                style=" position: absolute; top: -4vh; left: 4.5vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"> -->

                <a-menu-item style="line-height:30px;" @click="priorityChange(item, '高')" key="HIGH">高 </a-menu-item>
                <a-menu-item style="line-height:30px;" key="MIDDLE" @click="priorityChange(item, '普通')"
                  >普通
                </a-menu-item>
                <a-menu-item style="line-height:30px;" @click="priorityChange(item, '低')" key="LOW">低 </a-menu-item>
              </a-sub-menu>
            </template>
            <a-menu-item
              v-if="
                rejectStages.length > 1 &&
                  selectKeys.every(item => item.taskStatus !== '已完成') &&
                  selectKeys.every(item => item.taskStatus !== '驳回') &&
                  rejectShow
              "
              style="background-color: #fff !important;"
            >
              <div class="task-btn">
                <div
                  @click="
                    () => {
                      this.visible = !this.visible
                    }
                  "
                  style="width:50%"
                >
                  取消
                </div>
                <div :style="`color:${color};width:50%`" @click="submitTask">确定</div>
              </div>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-button-group style="display:flex">
          <a-tooltip>
            <template slot="title">
              看板
            </template>
            <a-button
              icon="appstore"
              @click="changeViewType('kanban')"
              :style="viewType == 'kanban' ? 'width:45px;z-index:9' : 'width:45px'"
              :type="viewType == 'kanban' ? 'primary' : ''"
              :ghost="viewType == 'kanban' ? true : false"
            ></a-button>
          </a-tooltip>
          <a-tooltip>
            <template slot="title">
              列表
            </template>
            <a-button
              icon="unordered-list"
              @click="changeViewType('list')"
              :style="viewType == 'list' ? 'width:45px;z-index:9' : 'width:45px'"
              :type="viewType == 'list' ? 'primary' : ''"
              :ghost="viewType == 'list' ? true : false"
            ></a-button>
          </a-tooltip>
          <a-tooltip>
            <template slot="title">
              日历
            </template>
            <a-button
              icon="table"
              @click="changeViewType('calendar')"
              :style="viewType == 'calendar' ? 'width:45px;z-index:9' : 'width:45px'"
              :type="viewType == 'calendar' ? 'primary' : ''"
              :ghost="viewType == 'calendar' ? true : false"
            ></a-button>
          </a-tooltip>
        </a-button-group>
      </div>
      <tab-tasks-table
        v-if="viewType == 'list'"
        :parent="parent"
        :allUsers="allUsers"
        @setRowkeys="setRowkeys"
        ref="table"
      />
      <panel-calendar
        @changeFinishDis="changeFinishDis"
        @changeViewTypeToWeek="changeViewTypeToWeek"
        :isWeek="isWeek"
        @getKey="getKey"
        :calendarType="calendarType"
        ref="calendarPanel"
        :parent="parent"
        @setSkipLoading="setSkipLoading"
        @setSelectKeys="setSelectKeys"
        :userInfo="userInfo"
        :selectKeys="selectKeys"
        v-if="viewType == 'calendar'"
      />
      <!-- <panel-kan-ban
        @changeTab="changeTab"
        @setSelectKeys="setSelectKeys"
        @setBatchDis="setBatchDis"
        :allUsers="allUsers"
        ref="kanBan"
        @getKey="getKey"
        :selectKeys="selectKeys"
        :parent="parent"
        v-if="viewType == 'kanban'"
      /> -->
      <task-board
        v-if="viewType == 'kanban'"
        @getKey="getKey"
        @setSelectKeys="setSelectKeys"
        :selectKeys="selectKeys"
        @setBatchDis="setBatchDis"
        :filterValue="filterValue"
        ref="kanBan"
        :parent="parent"
      />
      <month-list
        ref="monthList"
        @changeTaskList="changeTaskList"
        :allUsers="allUsers"
        :viewType="viewType"
        @getTaskTableData="getTaskTableData"
      />
      <tasks-model ref="tasksModel" @closeTasksModel="closeTasksModel" />
      <tasks-recycle-bin-modal :productionId="parent.id" :visible.sync="recycleBinVisible" @ok="modalFormOk" />
      <manuscript-list ref="manuscript" :title="'上传原稿'" :selectionRows="selectionRows" />
      <sumbmit-modal
        @openTaskModel="openTaskModel"
        ref="sumbmitModal"
        :title="'上传完成稿'"
        :selectionRows="selectionRows"
      />
      <term-list-modal ref="termListModal" />
      <font-modal ref="fontModal" @saveFont="saveFont" />
    </div>
  </a-spin>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { SortTwo } from '@icon-park/vue'
import StageModal from './stage-modal'
import Vue from 'vue'
import { USER_INFO, ACCESS_TOKEN } from '@/store/mutation-types'
import TasksRecycleBinModal from '@/views/production/modules/TasksRecycleBinModal'
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasks'
import { createAlink } from '@/utils/util'
import PanelCalendar from '@/views/modules/panel-calendar.vue'
import PanelKanBan from '@/views/modules/panel-kanban.vue'
import TaskBoard from '@/views/modules/task-board.vue'
import monthList from '@/components/monthList'
import termListModal from '@/components/termListModal'
import { getAction, postAction, deleteAction, v1postAction } from '../../../api/manage'
import { checkPermission } from '@/utils/hasPermission'
import tabTasksTable from '@/components/tab-tasks-table'
import tasksModel from '@/views/system/modules/mytasks/modules/tasksModel'
import manuscriptList from '@/components/manuscript-list'
import sumbmitModal from '@/components/sumbmit-modal'
import JSZip from 'jszip'
import { axios } from '@/utils/request'
import FileSaver from 'file-saver'
import { upstatusDownloadFile } from '@/utils/util.js'
import fontModal from '@/views/system/details/mytasks/modules/font-modal.vue'
export default {
  mixins: [JeecgListMixin],
  components: {
    SortTwo,
    fontModal,
    termListModal,
    StageModal,
    TasksRecycleBinModal,
    ActionsTasks,
    PanelCalendar,
    PanelKanBan,
    TaskBoard,
    tabTasksTable,
    tasksModel,
    monthList,
    manuscriptList,
    sumbmitModal
  },
  props: {
    parent: {
      type: Object,
      default: () => ({})
    },
    calcUnits: {
      type: Array,
      default: () => []
    },
    tab: {
      type: String,
      default: sessionStorage.getItem('current_name') || '1'
    },
    tasksTab: {
      type: String,
      default: sessionStorage.getItem('current_name') || '1'
    }
  },
  watch: {
    tab(n) {
      if (n == '3') {
        this.getKanBanData()
      }
      this.selectionRows = []
      this.selectKeys = []
    },
    dataSource(dataSource) {
      console.log('dataSource', { dataSource, 'this.parent': this.parent })
      this.$set(this, 'dataSource', dataSource)
      // 如果dataSource变动，更新看板视图，二次请求
      /* this.$refs.kanBan && this.$refs.kanBan.reloadData() */
    }
  },
  data() {
    return {
      viewType: 'kanban', //表示当前的显示方式,list列表，kanban看板，calendar日历
      recycleBinVisible: false,
      color1: '#4a4a4a',
      calendarType: 'dayGridMonth',
      isWeek: false,
      isCalendarCancel: false,
      isCalendarDropCancel: false,
      btnLoadding: false,
      filterValue: [],
      visible: false,
      batchDis: false,
      skipLoading: false,
      selectKeys: [],
      finishDis: false,
      selectedRowKeys: [],
      columns: [
        {
          title: '平台',
          dataIndex: 'platformName',
          width: 120,
          sorter: false
        },
        {
          title: '序号',
          dataIndex: 'productSequenceNo',
          width: 80,
          sorter: false
        },
        {
          title: '作品',
          dataIndex: 'productionName',
          width: 200,
          sorter: false
        },
        {
          title: '章节序号',
          dataIndex: 'chapterSequenceNo',
          width: 100,
          sorter: false,
          scopedSlots: { customRender: 'chapterSequenceNo' }
        },
        {
          title: '结算数量',
          dataIndex: 'calcUnits',
          width: 100,
          sorter: false,
          scopedSlots: { customRender: 'calcUnits' }
        },
        {
          title: '阶段',
          dataIndex: 'stageName',
          width: 120,
          sorter: false
        },
        {
          title: '人员',
          dataIndex: 'user.userName',
          width: 180,
          sorter: false,
          scopedSlots: { customRender: 'user' }
        },
        {
          title: '截止日期',
          dataIndex: 'deadline',
          width: 170,
          sorter: false,
          scopedSlots: { customRender: 'deadline' }
        },
        {
          title: '结算月份',
          dataIndex: 'settlementDate',
          width: 120,
          sorter: false,
          scopedSlots: { customRender: 'settlementDate' }
        },
        {
          title: '完成状态',
          // dataIndex: '',
          width: 80,
          sorter: false,
          scopedSlots: { customRender: 'completeStatus' }
        },
        {
          title: '时间间隔',
          dataIndex: 'timeInterval',
          width: 80,
          sorter: false
        },
        /*         {
          title: '优先级',
          dataIndex: 'priority',
          width: 100,
          sorter: false
        }, */
        {
          title: '完成情况',
          dataIndex: 'finishStatus',
          width: 100,
          sorter: false,
          scopedSlots: { customRender: 'finishStatus' }
        },
        {
          title: '考核结果',
          dataIndex: 'evalResult',
          width: 180,
          sorter: false,
          scopedSlots: { customRender: 'evalResult' }
        },
        {
          title: '考核得分',
          dataIndex: 'totalScore',
          width: 120,
          sorter: false,
          scopedSlots: { customRender: 'totalScore' }
        }
        /*         {
          title: '操作',
          // dataIndex: '',
          width: 200,
          sorter: false,
          scopedSlots: { customRender: 'action' },
        }, */
      ],
      rejectShow: false,
      mytasksDetialPath: '/translate/mytasks/detail',
      fromProduction: true,
      mytasksUploadPath: '/translate/mytasks/upload',
      translateMytasksDetialPath: '/translate/mytasks/index',
      translateEditorPath: '/translate/editor',
      allUsers: [],
      oldDataSource: [],
      rejectStages: [],
      isChangeTab: true,
      clickRowEditable: (record, index) => ({
        on: {
          click: () => {
            if (!record.editable) this.openDetail(record)
          }
        }
      }),
      isCancel: false,
      userInfo: {
        userFile: {}
      },
      isEditSelect: false,
      checkedList: [],
      color: '',
      isEditUser: false,
      downProgress: 0
    }
  },
  methods: {
    sortKanban() {
      if (this.$refs.kanBan) {
        this.$refs.kanBan.sortKanban()
      }
    },
    async downloadOver(isSection) {
      let arr = []
      this.selectionRows.forEach(item => {
        if (arr.every(a => a.chapterId != item.chapterId)) {
          arr.push(item)
        }
      })
      let that = this
      arr.forEach(async (item, index) => {
        let productOrder = item.productOrder + ''
        let chapterOrder = item.chapterOrder + ''
        if (productOrder.length == 1) {
          productOrder = '00' + productOrder
        } else if (productOrder.length == 2) {
          productOrder = '0' + productOrder
        }
        if (chapterOrder.length == 1) {
          chapterOrder = '00' + chapterOrder
        } else if (chapterOrder.length == 2) {
          chapterOrder = '0' + chapterOrder
        }
        const token = Vue.ls.get(ACCESS_TOKEN)
        let total = 0
        const res = await axios.post(
          Vue.prototype.API_BASE_URL +
            '/finish/manuscript/download?chapterId=' +
            item.chapterId +
            '&isSection=' +
            isSection,
          {},
          {
            headers: { 'X-Access-Token': token },
            responseType: 'blob',
            onDownloadProgress(progress) {
              // 监听下载进度的方法
              let downProgress = Math.round((100 * progress.loaded) / progress.total)
              total = progress.loaded || 0
              if (progress.total) {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection ? '下载切片-'+productOrder+'-'+item.productionName + '-' + chapterOrder : '下载终稿-'+productOrder+'-'+item.productionName + '-' + chapterOrder,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    },
                    duration: downProgress==100?0.3:null
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection ? '下载切片-'+productOrder+'-'+item.productionName + '-' + chapterOrder : '下载终稿-'+productOrder+'-'+item.productionName + '-' + chapterOrder,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    },
                    duration: downProgress==100?0.3:null
                  })
                }
              } else {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection ? '下载切片-'+productOrder+'-'+item.productionName + '-' + chapterOrder : '下载终稿-'+productOrder+'-'+item.productionName + '-' + chapterOrder,
                    duration: null,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M'
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection ? '下载切片-'+productOrder+'-'+item.productionName + '-' + chapterOrder : '下载终稿-'+productOrder+'-'+item.productionName + '-' + chapterOrder,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M',
                    duration: null
                  })
                }
              }
              open = true
              console.log(downProgress, progress.loaded, progress.total, 123)
            }
          }
        )
        if (!res) return
        const blob = res
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        let fileName = item.productionName + '-' + chapterOrder + '-终稿'
        if (item.departName.indexOf('原创部') == -1) {
          fileName = productOrder + '-' + chapterOrder
        }
        a.href = url
        a.download = fileName + '.zip'
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    checkPermission() {
      return checkPermission('production:task:button:change')
    },
    openTaskModel(data) {
      this.$refs.tasksModel.open(data.noUpdateTasks, data.hasFeedback)
    },
    async handleTaskStart() {
      this.visible = false
      const res = await getAction('/tasks/batch/resetTasks/' + this.selectedRowKeys.join(','))
      if (res.code == 200) {
        this.$message.success('可开始')
        if (this.$refs.table) this.$refs.table.getTableData()
        if (this.$refs.kanBan) this.$refs.kanBan.reloadData()
        if (this.$refs.calendarPanel) {
          this.$refs.calendarPanel.handleSelectOn()
          this.$refs.calendarPanel.reloadData()
        }
        this.selectedRowKeys = []
        this.selectKeys = []
        this.isCalendarCancel = false
      } else {
        this.$message.error(res.msg)
      }
    },
    changeViewTypeToWeek(type) {
      this.calendarType = type
      this.isWeek = type
    },
    downImg(fileList) {
      const zip = new JSZip()
      const cache = {}
      const promises = []
      fileList.forEach((item, i) => {
        const promise = this.getFile(item).then(data => {
          // 下载文件, 并存成blob对象
          const file_name = item.name // 获取文件名(因为重复的文件名只会下载一个，故需要加入下标 不同名)
          zip.file(file_name, data, { binary: true }) // 逐个添加文件
          cache[file_name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流   然后保存文件（如果这个下载不了 也可以将下方这一行换成a标签下载逻辑）
          FileSaver.saveAs(content, '翻译JPG.zip') // 利用file-saver保存文件  自定义文件名
        })
      })
    },
    getFile(item) {
      let url = item.url
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url, // 原先后端下载单个文件的路径
          // responseType: "ArrayBuffer"  // 注意看下你自己的原先后端下载地址返回的文件格式 是blob 还是其他的啥 对应这里就要改成后端返回的下载格式
          responseType: 'blob'
        })
          .then(res => {
            resolve(res.data) // 返回文件的blob   Blob {size: 151382, type: 'image/png'}
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    },
    downloadJpg() {
      if (this.selectionRows.every(item => item.hasTranslateText)) {
        /*         let files = []
        this.selectionRows.forEach(item => {
          item.originalManuscripts?.forEach(i => {
            files.push({ url: i.filePath, name: i.fileName.replace('psd', 'jpg') })
          })
        })
        this.downImg(files) */
        this.selectionRows.forEach(taskDetail => {
          localStorage.setItem('translate_record', JSON.stringify(taskDetail))
          let pageNum = (taskDetail.originalManuscripts && taskDetail.originalManuscripts.length) || 0
          window.open(
            `/translate/editor/${taskDetail.taskId}/${taskDetail.productionId}/${taskDetail.chapterId}/${taskDetail.platformId}?productionName=` +
              taskDetail.productionName +
              `&isDownload=true&pageNum=${pageNum}`
          )
        })
      } else {
        this.$message.error('无法下载翻译JPG，请确认是否有翻译文本')
      }
    },
    async downloadMake() {
      this.selectionRows.forEach(async item => {
        const rest = await v1postAction('/psd/get_chapter_make_psd_link', {
          productionId: item.productionId,
          chapterId: item.chapterId
        })
        if (rest.code == 200) {
          let postData = { list: [] }
          rest.data.forEach(data => {
            let makeArr = []
            data.textAreas.forEach(textArea => {
              if (!textArea.characterSentences.length) return
              let textArr = textArea.showText.split('\n')
              var reg = /^[a-zA-Z]+$/

              textArr = textArr.sort((a, b) => a.replaceAll('.', '').length - b.replaceAll('.', '').length)
              let textArrLength = 0
              textArr[textArr.length - 1].split('').forEach(text => {
                if (reg.test(text) || text == ' ') {
                  textArrLength = textArrLength + 0.5
                } else if (text == '.') {
                  textArrLength = textArrLength + 0.3
                } else {
                  textArrLength++
                }
              })
              textArrLength = textArrLength.toFixed(0) - 0
              let maxWidth =
                (
                  textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
                ).toFixed(0) - 0
              if (textArea.textLayout == 2) {
                maxWidth =
                  (
                    textArr.length *
                    textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                    textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
                  ).toFixed(0) - 0
              }
              let maxHeight =
                (
                  textArr.length *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
                ).toFixed(0) - 0
              if (textArea.textLayout == 2) {
                maxHeight =
                  (
                    textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
                  ).toFixed(0) - 0
              }
              let changeWidth = ((maxWidth - textArea.textAreaTranslationRegion.width) / 2).toFixed(0) - 0
              let changeHeight = ((maxHeight - textArea.textAreaTranslationRegion.height) / 2).toFixed(0) - 0
              let pushArr = []
              let brIndex = [-1]
              let newBrIndex = [-1]
              let changeBrIndex = []
              let phoneticArr = []
              let pushPhoneticText = ''
              let theX = 0
              let theY = 0
              let phoneticBrIndexOftext = []
              let arr = []
              textArea.showTexts = []
              textArea.characterSentences.forEach((text, textIndex) => {
                if (text.characterText == '\n') text.characterText = '<br>'
                if (text.characterText == '<br>') newBrIndex.push(textIndex)
                if (text.phonetic && text.phonetic.phoneticText) {
                  phoneticBrIndexOftext.push(newBrIndex[newBrIndex.length - 1])
                }
                if (text.characterText != '<br>') {
                  arr.push(text)
                } else {
                  textArea.showTexts.push({ pId: '', texts: arr, textIndex })
                  arr = []
                  if (
                    textIndex == textArea.characterSentences.length - 1 &&
                    !arr.length &&
                    text.characterText == '<br>'
                  ) {
                    textArea.showTexts.push({
                      pId: '',
                      texts: [
                        {
                          ...textArea.characterSentences[0],
                          characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                          characterText: ' '
                        }
                      ],
                      pushTtextIndex: textIndex
                    })
                  }
                }
                if (textIndex == textArea.characterSentences.length - 1 && arr.length) {
                  textArea.showTexts.push({ pId: '', texts: arr, pushTtextIndex: textIndex })
                }
              })
              textArea.showTexts = textArea.showTexts.sort((a, b) => b.texts.length - a.texts.length)
              phoneticBrIndexOftext = Array.from(new Set(phoneticBrIndexOftext))
              let enNum = 0
              textArea.characterSentences.forEach((text, textIndex) => {
                let markArr = []
                if (text.characterText == '<br>') {
                  brIndex.push(textIndex)
                  enNum = 0
                }
                let minusWidth = 0
                /*               if (changeWidth < 0) {
                if (phoneticBrIndexOftext.length) {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                } else {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                }
              } */
                minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                let textAlignWidth = 0
                if (text.font.textAlign == 'right' && textArea.showTexts[0]) {
                  textAlignWidth = textArea.showTexts[0].texts.length * text.font.fontSize
                }
                theX =
                  textArea.textLayout == 1
                    ? textArea.textAreaTranslationRegion.x - changeWidth + textAlignWidth
                    : textArea.textAreaTranslationRegion.x -
                      changeWidth +
                      phoneticBrIndexOftext.length * ((text.font.fontSize - 1) / 2 + 1) +
                      minusWidth
                theY =
                  textArea.textLayout == 1
                    ? textArea.textAreaTranslationRegion.y - changeHeight + text.font.fontSize
                    : textArea.textAreaTranslationRegion.y - changeHeight + textAlignWidth
                let pushCharacterText = text.characterText == '<br>' ? '\r' : text.characterText
                if (
                  textArea.characterSentences[textIndex - 1] &&
                  textArea.characterSentences[textIndex - 1].characterText == '<br>' &&
                  textArea.characterSentences[textIndex].characterText == ' '
                ) {
                  pushCharacterText = ''
                }
                let textPushArr = [
                  pushCharacterText == '\n' ? '\r' : pushCharacterText,
                  (text.font.fontSize - 1) / 4 - 0,
                  text.font.postScriptName,
                  { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                  { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                  text.font.textAlign,
                  0.0,
                  (text.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  theX,
                  theY,
                  text.font.strokeWidth || 0,
                  text.font.strokeColor
                    ? {
                        red: text.font.strokeColor.red,
                        green: text.font.strokeColor.green,
                        blue: text.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  0,
                  text.characterSentenceId
                ]
                if (reg.test(text.characterText)) {
                  if (
                    text.characterText == 'f' ||
                    text.characterText == 'i' ||
                    text.characterText == 'j' ||
                    text.characterText == 'l' ||
                    text.characterText == 't'
                  ) {
                    enNum = enNum + 0.5
                  } else {
                    if (text.characterText == 'A') {
                      enNum = enNum + 0.25
                    } /* else if(text.characterText=='B'){
                    enNum = enNum + 0.3
                  } */ else {
                      enNum = enNum + 0.3
                    }
                  }
                }
                if (text.font.hasMark) {
                  let markText = '.'
                  let markX = 0
                  if (!brIndex.length) {
                    markX = (textIndex - 1 - enNum) * text.font.fontSize + (text.font.fontSize / 2 - 0) - 3
                    if (textIndex > 8) {
                      markX = markX + ((textIndex - 8) / 3) * 2
                    }
                    if (text.font.isBold) {
                      markX = markX + textIndex
                    }
                  } else {
                    markX =
                      (textIndex - brIndex[brIndex.length - 1] - 1 - enNum) * text.font.fontSize +
                      (text.font.fontSize / 2 - 0) -
                      3
                    if (textIndex - brIndex[brIndex.length - 1] > 8) {
                      markX = markX + ((textIndex - brIndex[brIndex.length - 1] - 8) / 3) * 2
                    }
                    if (text.font.isBold) {
                      markX = markX + (textIndex - brIndex[brIndex.length - 1])
                    }
                  }
                  if (text.font.textAlign == 'right') {
                    markX = theX - markX
                  }
                  let markPushArr = [
                    markText,
                    ((text.font.fontSize - 1) / 4).toFixed(0) - 0,
                    text.font.postScriptName,
                    { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                    { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                    text.font.textAlign,
                    0.0,
                    (text.font.lineSpacing * 100).toFixed(0) - 0,
                    0.0,
                    textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                    textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                    text.font.textAlign == 'right' ? markX : theX + markX,
                    theY +
                      (brIndex.length - 2) +
                      (text.font.fontSize - 1) / 4 +
                      (((brIndex.length - 1) * text.font.fontSize * text.font.lineSpacing).toFixed(0) - 0),
                    text.font.strokeWidth || 0,
                    text.font.strokeColor
                      ? {
                          red: text.font.strokeColor.red,
                          green: text.font.strokeColor.green,
                          blue: text.font.strokeColor.blue
                        }
                      : { red: 255, green: 255, blue: 255 },
                    'antiAliasStrong',
                    textArea.textLayout == 1 ? 0 : 1,
                    0,
                    text.characterSentenceId
                  ]
                  markArr.push(markPushArr)
                  makeArr.push(markArr)
                }
                pushArr.push(textPushArr)
                if (text.phonetic && text.phonetic.phoneticText) {
                  if (text.phonetic.font) {
                    text.phonetic.font.postScriptName = text.font.postScriptName
                  }
                  let isFirstText = false
                  if (!textIndex || textArea.characterSentences[textIndex - 1].characterText == '<br>')
                    isFirstText = true
                  phoneticArr.push({
                    targetCharacterIds: text.phonetic.targetCharacterIds,
                    brNum: brIndex.length - 1,
                    phonetic: text.phonetic,
                    text,
                    textIndex,
                    isFirstText
                  })
                }
                if (
                  phoneticArr.findIndex(p => p.textIndex == textIndex) > -1 &&
                  phoneticArr.find(p => p.textIndex == textIndex).isFirstText &&
                  phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText.length > 3
                ) {
                  pushPhoneticText = ''
                  let pushPhoneticTextLength = phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText
                    .length
                  for (let i = 0; i < pushPhoneticTextLength - 3; i++) {
                    pushPhoneticText = pushPhoneticText + ' '
                  }
                }
              })
              if (brIndex.length && phoneticArr.length) {
                brIndex.forEach((brIdx, brIdxIndex) => {
                  brIdx = brIdx + 1 + brIdxIndex
                  pushArr.splice(brIdx, 0, [
                    pushPhoneticText,
                    (phoneticArr[0].phonetic.font.fontSize - 1) / 4 - 0,
                    pushArr[0][2],
                    pushArr[0][3],
                    pushArr[0][4],
                    pushArr[0][5],
                    pushArr[0][6],
                    pushArr[0][7],
                    pushArr[0][8],
                    pushArr[0][9],
                    pushArr[0][10],
                    theX,
                    theY,
                    pushArr[0][13],
                    pushArr[0][14],
                    pushArr[0][15],
                    textArea.textLayout == 1 ? 0 : 1,
                    pushArr[0][17]
                  ])
                  changeBrIndex.push(brIdx - 1)
                })
              }
              if (phoneticArr.length) {
                let phoneticBrIndex = [...changeBrIndex]
                let pushPhoneticBrIndexArr = []
                let pushPhoneticBrNum = 0
                phoneticArr.forEach((p, pIdx) => {
                  if (pushPhoneticBrIndexArr.indexOf(changeBrIndex[p.brNum]) > -1) {
                    return
                  }
                  pushArr.splice(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum, 0, [
                    '\r',
                    pushArr[0][1],
                    pushArr[0][2],
                    pushArr[0][3],
                    pushArr[0][4],
                    pushArr[0][5],
                    pushArr[0][6],
                    pushArr[0][7],
                    pushArr[0][8],
                    pushArr[0][9],
                    pushArr[0][10],
                    theX,
                    theY,
                    pushArr[0][13],
                    pushArr[0][14],
                    pushArr[0][15],
                    textArea.textLayout == 1 ? 0 : 1,
                    pushArr[0][17]
                  ])
                  /*                 changeBrIndex.forEach(c => {
                  if (
                    pushArr[c + pushPhoneticBrNum] &&
                    pushArr[c + pushPhoneticBrNum - 1] &&
                    pushArr[c + pushPhoneticBrNum][0] == '\r' &&
                    pushArr[c + pushPhoneticBrNum - 1][0] == '\r'
                  ) {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum - 1)
                  } else {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum)
                  }
                }) */
                  let pushBrNum = changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum
                  phoneticBrIndex[p.brNum] = pushBrNum
                  /* phoneticBrIndex.push(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum) */
                  pushPhoneticBrIndexArr.push(changeBrIndex[p.brNum])
                  pushPhoneticBrNum++
                })
                phoneticArr = phoneticArr.reverse()
                let phoneticSzie = 0
                let moveNum = 0
                brIndex = []
                pushArr.forEach((text, textIndex) => {
                  if (
                    text[0] == '\r' &&
                    ((pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r') ||
                      (pushArr[textIndex + 1] && pushArr[textIndex + 1][0] != '\r') ||
                      !textIndex)
                  ) {
                    /* if(pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r'&&pushArr[textIndex] && pushArr[textIndex][0] == '\r'){
                    brIndex.push(textIndex)
                  } */
                    brIndex.push(textIndex)
                  }
                })
                phoneticArr.forEach((p, pIdx) => {
                  if (!p.targetCharacterIds) p.targetCharacterIds = []
                  let menuis = brIndex[0] == 0 ? 0 : 1
                  moveNum =
                    pushArr.findIndex(text => text[18] == p.targetCharacterIds[0]) - brIndex[p.brNum - menuis] - 1
                  /* textArea.showText
                  .split('\n')
                  [p.brNum].split('')
                  .forEach((t, tEnIdx) => {
                    if (
                      reg.test(t) &&
                      textArea.characterSentences.findIndex(te => te.characterSentenceId == p.targetCharacterIds[0]) >
                        tEnIdx
                    ) {
                      moveNum--
                    }
                  }) */
                  p.moveNum = moveNum
                })
                let addTotal = phoneticArr.length
                let addArr = []
                phoneticArr.forEach((p, pIdx) => {
                  phoneticSzie = (p.phonetic.font.fontSize - 1) / 4 - 0
                  let sortNum = phoneticArr
                    .filter(cp => cp.brNum == p.brNum)
                    .sort((a, b) => {
                      return a.moveNum - b.moveNum
                    })
                  let addNum = 1
                  if (p.phonetic.phoneticText.length > 1) {
                    addNum = 0
                  } else if (p.phonetic.phoneticText.length == 1) {
                    addNum = 2
                  }
                  if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                    addNum = -2
                  }
                  console.log(p.moveWidth, p, sortNum, addNum)
                  if (p.moveNum != sortNum[0].moveNum) {
                    if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) {
                      if (p.phonetic.phoneticText.length > 2) {
                        addNum = 0
                      } else if (p.phonetic.phoneticText.length == 2) {
                        addNum = 2
                      } else if (p.phonetic.phoneticText.length == 1) {
                        addNum = 3
                      }
                      if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                        addNum = addNum + 1
                      if (!p.phonetic.font.postScriptName) {
                        this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                      }
                    }
                    p.moveWidth =
                      (p.moveNum - sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].moveNum - 1) * 8 + addNum
                    let changeNum =
                      (sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].targetCharacterIds.length - 1) * 4
                    /*                     sortNum.forEach((s,sIdx)=>{
                      if(sIdx<sortNum.findIndex(s => s.moveNum == p.moveNum)){
                        changeNum=changeNum+(s.targetCharacterIds.length-1)*3
                      }
                    }) */
                    p.moveWidth = p.moveWidth - changeNum
                    if (p.phonetic.phoneticText.length > 5 && p.targetCharacterIds.length == 1) {
                      p.moveWidth = p.moveWidth - 1
                    }
                    if (p.targetCharacterIds.length > 1) {
                      p.moveWidth = p.moveWidth + (p.targetCharacterIds.length - 1) * 4
                    }
                  } else {
                    if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                      addNum = addNum + (p.moveNum == 1 ? 2 : 1)
                    if (!p.phonetic.font.postScriptName) {
                      this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                    }
                    if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) addNum = 1
                    p.moveWidth = (p.moveNum - 2 + p.targetCharacterIds.length) * 10 + addNum - 2
                    /* if (p.phonetic.phoneticText.length == 4 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = p.moveWidth - 2
                  } */
                    /*  if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  }
                  if (p.phonetic.phoneticText.length == 2 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  } */
                    if (p.moveNum == 1) {
                      p.moveWidth = (p.targetCharacterIds.length - 1) * 4 + 2
                    }
                  }
                  let addText = ''
                  for (let i = 0; i < p.moveWidth; i++) {
                    addText = addText + ' '
                  }
                  addArr.push({
                    index: phoneticBrIndex[p.brNum] + (phoneticBrIndex[p.brNum] ? addTotal : addTotal - 1),
                    addText,
                    text: p.text,
                    p
                  })
                  addTotal--
                  pushArr.splice(phoneticBrIndex[p.brNum] ? phoneticBrIndex[p.brNum] + 1 : 0, 0, [
                    p.phonetic.phoneticText,
                    (p.phonetic.font.fontSize - 1) / 4 - 0,
                    p.phonetic.font.postScriptName,
                    {
                      red: p.text.font.fontColor.red,
                      green: p.text.font.fontColor.green,
                      blue: p.text.font.fontColor.blue
                    },
                    { bold: p.phonetic.font.isBold ? true : false, italic: p.phonetic.font.isIncline ? true : false },
                    'center',
                    p.phonetic.font.letterSpacing,
                    (p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                    0.0,
                    p.phonetic.font.textHeight * 100,
                    p.phonetic.font.textWidth * 100,
                    theX,
                    theY,
                    p.phonetic.font.strokeWidth || 0,
                    p.phonetic.font.strokeColor
                      ? {
                          red: p.phonetic.font.strokeColor.red,
                          green: p.phonetic.font.strokeColor.green,
                          blue: p.phonetic.font.strokeColor.blue
                        }
                      : { red: 255, green: 255, blue: 255 },
                    'antiAliasStrong',
                    textArea.textLayout == 1 ? 0 : 1,
                    1
                  ])
                })
                addArr = addArr.reverse()
                addArr.forEach((a, aIdx) => {
                  pushArr.splice(a.index + aIdx, 0, [
                    a.addText,
                    (a.p.phonetic.font.fontSize - 1) / 4 - 0,
                    'AdobeHeitiStd-Regular',
                    {
                      red: a.text.font.fontColor.red,
                      green: a.text.font.fontColor.green,
                      blue: a.text.font.fontColor.blue
                    },
                    {
                      bold: a.p.phonetic.font.isBold ? true : false,
                      italic: a.p.phonetic.font.isIncline ? true : false
                    },
                    'center',
                    a.p.phonetic.font.letterSpacing,
                    (a.p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                    0.0,
                    100,
                    100,
                    theX,
                    theY,
                    a.p.phonetic.font.strokeWidth || 0,
                    a.p.phonetic.font.strokeColor
                      ? {
                          red: a.p.phonetic.font.strokeColor.red,
                          green: a.p.phonetic.font.strokeColor.green,
                          blue: a.p.phonetic.font.strokeColor.blue
                        }
                      : { red: 255, green: 255, blue: 255 },
                    'antiAliasStrong',
                    textArea.textLayout == 1 ? 0 : 1,
                    1
                  ])
                })
                if (pushPhoneticText.length) {
                  pushArr.forEach(a => {
                    if (a[16] == 1) {
                      a[11] = a[11] + pushPhoneticText.length * (phoneticSzie / 2)
                    } else {
                      a[12] = a[12] - pushPhoneticText.length * (phoneticSzie / 2)
                    }
                  })
                }
              }
              console.log(pushArr) //pushPhoneticBrIndexArr有问题
              debugger
              makeArr.push(pushArr)
            })

            postData.list.push(makeArr)
          })

          const res = await v1postAction('/psd/test', {
            ...postData,
            productionId: item.productionId,
            chapterId: item.chapterId
          })
          if (res.code == 1) {
            let name = this.parent.productionName + '-' + item.chapterOrder + '-制作稿.zip'
            let xhr = new XMLHttpRequest()
            // GET请求,link,async(是否异步)
            xhr.open('GET', '/api/v1' + res.data, true)
            xhr.setRequestHeader('X-Access-Token', Vue.ls.get(ACCESS_TOKEN))
            xhr.responseType = 'blob'
            xhr.onload = function(e) {
              if (this.status == 200) {
                let blob = this.response
                let a = document.createElement('a')
                // blob.type = "application/octet-stream";
                //创键临时link对象
                let url = window.URL.createObjectURL(new Blob([blob]))
                a.href = url
                a.download = name
                a.click()
                // 释放之前创建的URL对象
                window.URL.revokeObjectURL(url)
                a.remove()
              }
            }
            // //发送请求
            xhr.send()
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    openMonthList() {
      this.$refs.monthList.open(this.selectionRows)
    },
    modalFormOk() {
      // 新增/修改 成功时，重载列表
      this.loadData()
      if (this.$refs.table) this.$refs.table.getTableData()
      if (this.viewType == 'kanban') this.$refs.kanBan.reloadData()
      //清空列表选中
      this.onClearSelected()
    },
    onChange(e, item) {
      if (
        e.target.checked &&
        this.checkedList.every(i => {
          return i.stageId != item.stageId
        })
      ) {
        this.checkedList.push(item)
      } else if (
        !e.target.checked &&
        this.checkedList.some(i => {
          return i.stageId == item.stageId
        })
      ) {
        this.checkedList = this.checkedList.filter(i => {
          return i.stageId != item.stageId
        })
      }
    },
    async openManuscriptList() {
      let arr = []
      this.selectionRows.forEach(async item => {
        const res = await getAction('/production/get_guide', {
          current: 1,
          fieId: 'guidName',
          id: item.productionId,
          size: -1,
          typeId: 1
        })
        if (res.code == 200) {
          let isPass = true
          if (res.data.records.filter(item => item.classId == 4).length > 1) {
            this.$refs.fontModal.openModal(res.data.records.filter(item => item.classId == 4))
            isPass = false
          }
          arr.push(isPass)
        } else {
          this.$message.error(res.msg)
        }
      })
      if (arr.every(a => a)) {
        this.$refs.manuscript.open()
      }
    },
    priorityChange(item, type) {
      if (this.rejectStages.length == 1) {
        this.rejectStages[0].stageName = this.rejectStages[0].stageName.split('-')[0] + '-' + type
        this.checkedList = this.rejectStages
        this.submitTask()
      }
      item.stageName = item.stageName.split('-')[0] + '-' + type
      item.checked = true
      if (
        this.checkedList.every(i => {
          return i.stageId != item.stageId
        })
      ) {
        this.checkedList.push(item)
      }
    },
    batchDel: function() {
      console.log('JeecgListMixin batchDel')
      if (!this.url.deleteBatch) {
        this.$message.error('请设置url.deleteBatch属性!')
        return
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择至少一项任务！')
        return
      } else {
        var ids = this.selectedRowKeys.join(',')
        var that = this

        let url = that.url.deleteBatch
        // 增加作品管理的产品id
        if (this.productionId) {
          url = `${url}/${this.productionId}`
        } else if (this.platformId) {
          url = `${url}/${this.platformId}`
        }

        this.$confirm({
          title: '确认删除',
          content: '是否删除选中数据?',
          onOk: function() {
            that.loading = true
            deleteAction(url, { ids: ids })
              .then(res => {
                if (res.success) {
                  that.$message.success(res.message || res.msg ? '删除成功' : '')
                  if (that.$refs.newTable) {
                    that.$refs.newTable.loadData()
                  } else {
                    that.loadData()
                    if (that.$refs.table) that.$refs.table.getTableData()
                    if (that.viewType == 'kanban') that.$refs.kanBan.reloadData()
                  }
                  that.onClearSelected()
                  that.$emit('loadData')
                } else {
                  that.$message.warning(res.message || res.msg)
                }
              })
              .finally(() => {
                that.loading = false
              })
          }
        })
      }
    },
    changeTaskList(data) {
      console.log(data)
      if (data.settlementDate && !data.settleStatus) {
        this.getTaskTableData()
      } else if (!data.settlementDate && data.settleStatus) {
        this.selectionRows.forEach(item => {
          item.settleStatus = data.settleStatus
        })
        this.$refs.table.clearSelectKeys()
      } else if (data.settlementDate && data.settleStatus) {
        this.getTaskTableData()
      }
    },
    setRowkeys(records) {
      records = records.filter(item => !item.hasChild)
      this.selectedRowKeys = records.map(item => {
        return item.id
      })
      this.selectionRows = records
      this.selectKeys = this.selectionRows
      this.rejectShow = this.selectKeys.every(item => item.stageName == '甲方反馈') ? false : true
    },
    changeUserSelect() {
      if (this.$refs.table) {
        if (!this.$refs.table.selectRows.length) {
          this.$message.warning('请选择至少一项任务！')
          return
        }
        this.$refs.table.handleUserSelectOn()
      }
      if (this.$refs.kanBan) {
        if (!this.selectKeys.length) {
          this.$message.warning('请选择至少一项任务！')
          return
        }
        this.$refs.kanBan.handleUserSelectOn()
      }
      this.isEditSelect = false
    },
    changeEditSelect() {
      if (!this.selectionRows.length && this.viewType == 'list') {
        this.$message.warning('请选择至少一项任务！')
        return
      }
      if (this.isEditSelect) {
        if (this.$refs.table) this.$refs.table.getTableData()
      }
      if (this.$refs.kanBan) {
        this.$refs.kanBan.enterEdit()
        if (!this.selectKeys.length) {
          return
        }
      }
      this.isEditSelect = !this.isEditSelect
      if (this.$refs.table) this.$refs.table.handleEditSelectOn(this.isEditSelect)
      if (!this.isEditSelect) {
        this.selectionRows = []
        this.selectKeys = []
        this.$forceUpdate()
      }
    },
    changeDropSelect() {
      this.$refs.calendarPanel.handleDropSelectOn()
      this.isCalendarDropCancel = !this.isCalendarDropCancel
      this.isEditSelect = false
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      getAction(this.url.list, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          res.data = res.data || res.data
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.data.records || res.data
          this.dataSource.forEach(item => {
            item.totalScore = ''
            item.evalResult = {
              kpiValueErrors: []
            }
          })
          if (res.data.total) {
            this.ipagination.total = res.data.total - 0
          } else {
            this.ipagination.total = 0
          }
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    },
    getKanBanData() {
      /* this.$refs.kanBan.getKanBanData() */
      if (this.$refs.kanBan) this.$refs.kanBan.reloadData()
      if (this.$refs.calendarPanel) this.$refs.calendarPanel.reloadData()
      if (this.$refs.table) this.$refs.table.getTableData()
    },
    reKanbanData() {
      this.$refs.kanBan.kanbanData = {
        chapters: []
      }
    },
    getCheckboxProps(record) {
      return {
        props: {
          disabled: this.selectionRows.some(item => {
            return item.editable == true
          }) // 禁选项
        }
      }
    },
    async openSumbmitList() {
      let arr = []
      this.selectionRows.forEach(async item => {
        const res = await getAction('/production/get_guide', {
          current: 1,
          fieId: 'guidName',
          id: item.productionId,
          size: -1,
          typeId: 1
        })
        if (res.code == 200) {
          let isPass = true
          if (res.data.records.filter(item => item.classId == 4).length > 1) {
            this.$refs.fontModal.openModal(res.data.records.filter(item => item.classId == 4))
            isPass = false
          }
          arr.push(isPass)
        } else {
          this.$message.error(res.msg)
        }
      })
      if (arr.every(a => a)) {
        this.$refs.sumbmitModal.open()
      }
    },
    saveFont(selectedRowKeys) {
      var ids = selectedRowKeys.join(',')
      var that = this
      let url = '/production/delete_guide_batch/'
      if (this.taskDetail.productionId) {
        url = `${url}/${this.taskDetail.productionId}`
      } else if (this.taskDetail.platformId) {
        url = `${url}/${this.taskDetail.platformId}`
      }
      deleteAction(url, { ids: ids }).then(res => {
        if (res.success) {
          that.$message.success(res.message || res.msg ? '保存成功' : '')
          that.loadData()
        } else {
          that.$message.warning(res.message || res.msg)
        }
      })
    },
    setBatchDis(dis) {
      this.batchDis = dis
    },
    checkPermission() {
      return checkPermission('production:task:button:edit')
    },
    setSkipLoading(skipLoading) {
      this.skipLoading = skipLoading
    },
    batchSelect() {
      this.$refs.kanBan.batchSelect()
      this.isCancel = !this.isCancel
      this.selectKeys = []
      this.$forceUpdate()
    },
    kpiViewList(evalResult) {
      if (!evalResult) return []
      return JSON.parse(evalResult)
      /* return Object.entries(errorTypeScoreMap)
        .map(([type, score]) => ({ type, score }))
        .filter(i => i.score > 0) */
    },
    getTaskStatus(taskStatus) {
      let text =
        taskStatus == 'FINISHED'
          ? '已完成'
          : taskStatus == 'STARTED'
          ? '可开始'
          : taskStatus == 'WAITING'
          ? '等待'
          : taskStatus == 'REJECTING'
          ? '驳回'
          : ''
      return text
    },
    setSelectKeys() {
      this.selectKeys = []
      this.selectionRows = []
      this.selectedRowKeys = []
    },
    changeDropSelect() {
      this.$refs.calendarPanel.handleDropSelectOn()
      this.isCalendarDropCancel = !this.isCalendarDropCancel
    },
    changeSelect() {
      this.$refs.calendarPanel.handleSelectOn()
      this.isCalendarCancel = !this.isCalendarCancel
      this.isEditSelect = false
      this.selectKeys = []
      this.selectionRows = []
      this.selectedRowKeys = []
    },
    changeTab() {
      if (this.tasksTab != this.tab) {
        this.$emit('tabChange', this.tasksTab)
        let allUsers = this.allUsers
        Object.assign(this._data, this.$options.data())
        this.allUsers = allUsers
      }
      this.isChangeTab = true
    },
    async kanbanCancel() {
      if (this.viewType == 'kanban') {
        this.isChangeTab = false
        await this.$refs.kanBan.cancel(true)
      }
    },
    cancel() {
      let self = this
      if (this.oldDataSource.length > 0) {
        if (JSON.stringify(this.oldDataSource) != JSON.stringify(this.dataSource)) {
          this.isChangeTab = false
          this.$confirm({
            title: '提示',
            content: '您尚未保存数据，需要保存吗？',
            async onOk() {
              self.selectedRowKeys = []
              self.selectionRows = []
              self.dataSource.forEach(item => {
                item.editable = false
                if (item.deadline != null) {
                  item.deadline = item.deadline + ''
                  if (item.deadline.length <= 17) item.deadline = item.deadline + ':00'
                }
                item.ownerId = item.user.userId
                delete item.priority
              })
              const res = await postAction('/tasks/batch/updateTask', self.dataSource)
              if (res.code == 200) {
                self.$message.success('保存成功')
                self.selectedRowKeys = []
                self.selectionRows = []
              } else {
                self.$message.error(res.msg)
              }
              self.loadData()
              self.isChangeTab = true
            },
            onCancel() {
              self.selectedRowKeys = []
              self.selectionRows = []
              self.dataSource.forEach(item => {
                item.editable = false
              })
              if (self.tasksTab != self.tab) {
                self.$emit('tabChange', self.tasksTab)
                let allUsers = self.allUsers
                Object.assign(self._data, self.$options.data())
                self.allUsers = allUsers
              }
              self.loadData()
              self.isChangeTab = true
            }
          })
        } else {
          this.selectedRowKeys = []
          this.selectionRows = []
          this.dataSource.forEach(one => {
            one.editable = false
            delete one.taskStatus
          })
          this.isChangeTab = true
        }
      }
      this.loadData()
    },
    submitTask() {
      let targetList = []
      this.checkedList.forEach(item => {
        if (item.stageName.split('-').length > 1) {
          targetList.push({
            stageId: item.stageId,
            priority:
              item.stageName.split('-')[1] == '高'
                ? 'HIGH'
                : item.stageName.split('-')[1] == '普通'
                ? 'MIDDLE'
                : item.stageName.split('-')[1] == '低'
                ? 'LOW'
                : ''
          })
        } else {
          targetList.push({ stageId: item.stageId, priority: 'MIDDLE' })
        }
      })
      if (!this.checkedList?.length) return
      this.handleTasks(targetList)
    },
    async handleTasks(targetList) {
      this.visible = false
      const params = {
        operator: 'REJECTING',
        sourceTaskIds: this.selectKeys.map(item => item.taskId),
        targetList: targetList
      }
      /* const rest = await postAction('/tasks/noKpiBatch', { taskIds: params.sourceTaskIds }) */
      const res = await postAction('/tasks/batch/updateStatus', params)
      if (res.code == 200) {
        this.$message.success('驳回成功')
        this.rejectStages = []
        this.selectKeys = []
        this.selectedRowKeys = []
        this.isCalendarCancel = false
        Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
          item.checked = false
        })
        if (this.viewType == 'kanban') this.$refs.kanBan.reloadData()
        if (this.viewType == 'list') this.$refs.table.getTableData()
        if (this.viewType == 'calendar') {
          /*           this.$refs.calendarPanel.handleSelectOn()
          this.$refs.calendarPanel.changeStatusData(res.data) */
          this.$refs.calendarPanel.handleSelectOn()
          this.$refs.calendarPanel.reloadData()
        }
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    async handleTaskFine(hasFeedback) {
      this.visible = false
      let params = { operator: 'FINISHED', sourceTaskIds: [] }
      if (this.selectionRows.length > 0) {
        params.sourceTaskIds = this.selectionRows.map(item => item.taskId)
        if (this.selectionRows.some(item => item.stageName == '甲方反馈')) {
          params.hasFeedback = hasFeedback
        }
      } else {
        params.sourceTaskIds = this.selectKeys.map(item => item.taskId)
        if (this.selectKeys.some(item => item.stageName == '甲方反馈')) {
          params.hasFeedback = hasFeedback
        }
      }
      let that = this
      if (params.hasFeedback === false) {
        this.$confirm({
          content: h => <div>请确认是否真的没有反馈</div>,
          okText: '是',
          cancelText: '否',
          async onOk() {
            this.skipLoading = true
            const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
            if (response.code == 200) {
              if (response.data.productionList && response.data.productionList.length) {
                that.$refs.termListModal.open(response.data.productionList)
              } else {
                const res = await postAction('/tasks/batch/updateStatus', params)
                this.skipLoading = false
                if (res.code == 200) {
                  that.rejectStages = []
                  that.selectionRows = []
                  that.selectedRowKeys = []
                  that.selectKeys = []
                  that.isCalendarCancel = false
                  Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                    item.checked = false
                  })
                  if (res.data.noUpdateTasks.length) {
                    that.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                  } else {
                    that.$message.success('已完成')
                    upstatusDownloadFile(params.sourceTaskIds)
                    const rest = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
                    if (that.$refs.table) that.$refs.table.getTableData()
                    if (that.$refs.kanBan) that.$refs.kanBan.reloadData()
                  }
                  if (that.$refs.calendarPanel && res.data.updatedTasks.length) {
                    that.$refs.calendarPanel.handleSelectOn()
                    that.$refs.calendarPanel.changeStatusData(res.data.updatedTasks)
                  }
                } else {
                  that.$message.error(res.msg || res.message)
                }
              }
            }
          },
          onCancel() {},
          class: ''
        })
        return
      } else {
        if (
          (this.selectionRows.length &&
            this.selectionRows.some(
              item =>
                item.useTranslationSystem &&
                (item.stageName == '翻译' || item.stageName == '校对' || item.stageName == '终审')
            )) ||
          (this.selectKeys.length &&
            this.selectKeys.some(
              item =>
                item.useTranslationSystem &&
                (item.stageName == '翻译' || item.stageName == '校对' || item.stageName == '终审')
            ))
        ) {
          this.$confirm({
            content: h => <div>请确认当前任务的工作已经全部完成，提前点完成/未完成点完成均会影响后续考核。</div>,
            okText: '是',
            cancelText: '否',
            onOk: async () => {
              this.skipLoading = true
              const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
              if (response.code == 200) {
                if (response.data.productionList && response.data.productionList.length) {
                  this.$refs.termListModal.open(response.data.productionList)
                  this.skipLoading = false
                } else {
                  const res = await postAction('/tasks/batch/updateStatus', params)
                  this.skipLoading = false
                  if (res.code == 200) {
                    this.rejectStages = []
                    this.selectionRows = []
                    this.selectedRowKeys = []
                    this.selectKeys = []
                    this.isCalendarCancel = false
                    Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                      item.checked = false
                    })
                    /*         if (res.data.noKpiTasks) {
          res.data.noKpiTasks?.forEach(item => {
            this.$message.error(
              '【' + item.productionName + '-' + item.chapterSequenceNo + '】' + '对其他阶段考核未填写',
              5
            )
          })
          if (this.$refs.calendarPanel) {
            this.$refs.calendarPanel.handleSelectOn()
            this.$refs.calendarPanel.changeStatusData([])
          }
          return
        } */
                    if (res.data.noUpdateTasks.length) {
                      this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                    } else {
                      upstatusDownloadFile(params.sourceTaskIds)
                      this.$message.success('已完成')
                      if (this.$refs.table) this.$refs.table.getTableData()
                      if (this.$refs.kanBan) this.$refs.kanBan.reloadData()
                    }
                    if (this.$refs.calendarPanel && res.data.updatedTasks.length) {
                      this.$refs.calendarPanel.handleSelectOn()
                      this.$refs.calendarPanel.changeStatusData(res.data.updatedTasks)
                    }
                  } else {
                    this.$message.error(res.msg || res.message)
                  }
                }
              }
            }
          })
        } else {
          this.skipLoading = true
          const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
          if (response.code == 200) {
            if (response.data.productionList && response.data.productionList.length) {
              this.$refs.termListModal.open(response.data.productionList)
              this.skipLoading = false
            } else {
              const res = await postAction('/tasks/batch/updateStatus', params)
              this.skipLoading = false
              if (res.code == 200) {
                this.rejectStages = []
                this.selectionRows = []
                this.selectedRowKeys = []
                this.selectKeys = []
                this.isCalendarCancel = false
                Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                  item.checked = false
                })
                /*         if (res.data.noKpiTasks) {
          res.data.noKpiTasks?.forEach(item => {
            this.$message.error(
              '【' + item.productionName + '-' + item.chapterSequenceNo + '】' + '对其他阶段考核未填写',
              5
            )
          })
          if (this.$refs.calendarPanel) {
            this.$refs.calendarPanel.handleSelectOn()
            this.$refs.calendarPanel.changeStatusData([])
          }
          return
        } */
                if (res.data.noUpdateTasks.length) {
                  this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                } else {
                  upstatusDownloadFile(params.sourceTaskIds)
                  this.$message.success('已完成')
                  if (this.$refs.table) this.$refs.table.getTableData()
                  if (this.$refs.kanBan) this.$refs.kanBan.reloadData()
                }
                if (this.$refs.calendarPanel && res.data.updatedTasks.length) {
                  this.$refs.calendarPanel.handleSelectOn()
                  this.$refs.calendarPanel.changeStatusData(res.data.updatedTasks)
                }
              } else {
                this.$message.error(res.msg || res.message)
              }
            }
          }
        }
      }
    },
    getTaskTableData() {
      if (this.$refs.table) this.$refs.table.getTableData()
      if (this.$refs.kanBan) {
        this.$refs.kanBan.reloadData()
      }
      this.rejectStages = []
      this.selectionRows = []
      this.selectedRowKeys = []
      this.selectKeys = []
    },
    closeTasksModel(data) {
      if (!data) {
        if (this.$refs.table) this.$refs.table.getTableData()
        if (this.$refs.kanBan) {
          this.$refs.kanBan.reloadData()
        }
        if (this.$refs.sumbmitModal) {
          this.$refs.sumbmitModal.getTableData()
        }
        return
      }
      this.rejectStages = []
      this.selectionRows = []
      this.selectedRowKeys = []
      this.selectKeys = []
      this.isCalendarCancel = false
      Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
        item.checked = false
      })
      if (this.$refs.table) this.$refs.table.getTableData()
      if (this.$refs.calendarPanel && data.updatedTasks.length) {
        this.$refs.calendarPanel.handleSelectOn()
        this.$refs.calendarPanel.changeStatusData(data.updatedTasks)
      }
    },
    visibleChange() {
      setTimeout(() => {
        this.visible = !this.visible
      }, 100)
    },
    async getRejectStages() {
      let stageId = this.selectKeys[0].stageId
      this.rejectShow = this.selectKeys.every(item => item.stageName == '甲方反馈') ? false : true
      const res = await getAction('/production/canRejectStage', { productId: this.parent.productionId, stageId })
      try {
        if (res.code == 200) {
          this.rejectStages = res.data
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        this.$message.error(err)
      }
    },
    getKey(data) {
      this.rejectStages = []
      if (data.checked) {
        this.selectKeys.push(data)
      } else {
        if (this.selectKeys.findIndex(item => item.taskId == data.taskId) > -1) {
          this.selectKeys.splice(
            this.selectKeys.findIndex(item => item.taskId == data.taskId),
            1
          )
        }
      }
      this.selectedRowKeys = this.selectKeys.map(item => item.taskId)
      if (this.viewType == 'kanban') {
        this.selectKeys?.forEach(item => {
          if (this.getTaskStatus(item.taskStatus)) {
            item.taskStatus = this.getTaskStatus(item.taskStatus)
          }
        })
      }
      this.selectKeys?.forEach(item => {
        item.taskName = item.stageName
      })
      this.selectionRows = this.selectKeys
      this.rejectShow = this.selectKeys.every(item => item.stageName == '甲方反馈') ? false : true
    },
    changeFinishDis(type) {
      this.finishDis = type
      console.log(this.finishDis, this.selectKeys)
    },
    handleLinkTranslateMytasks() {
      createAlink(this.translateMytasksDetialPath, '_self')
      // this.$router.path({ path: this.translateMytasksDetialPath })
    },
    handleLinkTasks(record) {
      if (record.stageName === '给稿') {
        this.$router.push({
          path: `${this.mytasksUploadPath}/${record.taskId}/${record.productionId}/${record.chapterId}`,
          query: {
            record: encodeURIComponent(JSON.stringify(record))
          }
        })
      } else {
        this.$router.push({
          path: `${this.translateEditorPath}/${record.taskId}/${record.productionId}/${record.chapterId}/${record.platformId}`
        })
      }
    },
    changeViewType(viewType) {
      /*       if (this.viewType != viewType && viewType == 'list') {
        this.loadData()
      } */
      /* if (viewType == 'kanban') {
        setTimeout(() => {
          this.getKanBanData()
        }, 0)
      } */
      this.viewType = viewType
      this.selectKeys = []
      this.selectedRowKeys = []
      this.selectionRows = []
      this.isCalendarDropCancel = false
    },
    async enterEdit() {
      let isEdit = false
      this.selectionRows.forEach(item => {
        if (item.editable == true) {
          isEdit = false
        } else {
          isEdit = true
        }
      })
      if (!isEdit) {
        this.dataSource.forEach(item => {
          if (item.deadline != null) {
            item.deadline = item.deadline + ''
            if (item.deadline.length <= 17) item.deadline = item.deadline + ':00'
          }
          if (item.settlementDate != null) {
            item.settlementDate = item.settlementDate + ''
            if (item.settlementDate.length <= 9) item.settlementDate = item.settlementDate + '-01'
          }
          this.getUsersByStage(item.stageId)
          delete item.priority
        })
        const res = await postAction('/tasks/updateTasks', this.dataSource)
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.selectedRowKeys = []
          this.selectionRows = []
        } else {
          this.$message.error(res.msg || res.message)
        }
        this.loadData()
      } else {
        this.selectedRowKeys.forEach(item => {
          this.dataSource.forEach(one => {
            if (one.id == item) {
              one.editable = true
            }
            delete one.taskStatus
          })
        })
        this.oldDataSource = JSON.parse(JSON.stringify(this.dataSource))
        this.dataSource = Object.assign([], this.dataSource)
      }
    },
    quitEdit(record) {
      record.editable = false
      this.dataSource = Object.assign([], this.oldDataSource)
      this.selectedRowKeys = []
    },
    saveRecord(record) {
      record.editable = false
      this.selectedRowKeys = []
      postAction('/tasks/edit', record).then(res => {
        this.loadData()
      })
    },
    getUsersByStage(stageId) {
      getAction('/sys/user/list_all_users_by_stage', { stageId: stageId }).then(res => {
        this.allUsers = Object.assign([], res.data)
      })
    },
    getAllUsers() {
      setTimeout(() => {
        postAction('/sys/user/queryUser', { departmentIds: this.parent.departmentIds }).then(res => {
          this.allUsers = Object.assign([], res.data)
        })
      }, 1000)
    }
  },
  created() {
    this.userInfo = Vue.ls.get(USER_INFO) || {}
  },
  mounted() {
    this.getAllUsers()
    this.color = this.$store.state.app.color
    if (this.$refs.kanBan) this.$refs.kanBan.load()
  },
  computed: {
    url: {
      get: function() {
        return {
          list: `/production/list_tasks?id=${this.parent.id}`,
          deleteBatch: `/production/delete_task_batch`
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.circular {
  display: inline-block;
  width: 10px;
  height: 10px;
  //   background-color: grey;
  border-radius: 100%;
  margin-right: 5px;
  cursor: pointer;
}

.ant-select-selection__rendered {
  img {
    display: none;
  }
}
</style>
<style>
.ant-card-body {
  padding-bottom: 0px !important;
}
</style>
